import * as React from "react"
import loadable from "@loadable/component"
import { useCurrentUser } from "../hooks/useCurrentUser"
import { Router } from "@reach/router"
import Profile from "../views/users/Profile"
import Pulls from "../views/users/Pulls"
import Activity from "../views/users/Activity"
import Invoices from "../views/users/Invoices"
import Invoice from "../views/users/Invoice"
import { Container } from "../components/Container"

const User = loadable(() => import("../views/users/User"))
const Header = loadable(() => import("../components/Header"))
const PageWrapper = loadable(() => import("../pageWrapper"))

const UserPage: React.FC = props => {
  const { currentUser } = useCurrentUser()

  return (
    <PageWrapper>
      <div>
        <Header />

        <Router>
          <User path="user" user={currentUser}>
            <Profile path="profile" />
            <Pulls path="pulls" />
            <Invoices path="invoices">
              <Invoice path=":id" />
            </Invoices>
            <Activity path="activity" />
          </User>
        </Router>
      </div>
    </PageWrapper>
  )
}

export default UserPage
